import { computed, readonly, ref, watch } from 'vue'

import { updateMainColor, uploadLogo } from 'src/api/workspace'
import { useTheme } from 'src/composables/useTheme'
import { PartnerType } from 'src/types/partner'
import { Acl } from 'src/types/user'
import { Workspace, WorkspaceId } from 'src/types/workspace'

import { usePartner } from './partner'
import { useUser } from './user'

const isWorkspaceContext = ref<boolean>(false)
const currentWorkspace = ref<Workspace | null>(null)
const currentWsId = ref<number | null>(null)
const currentWsACL = ref<string | null>(null)
const currentWsDefaultPartnerType = ref<PartnerType | null>(null)
const currentWsMainColor = ref<string>('#2D50E6')
const invitedWsId = ref<number | null>(null)

const isLukoWs = computed(() => currentWsId.value === WorkspaceId.LUKO)

const isNoveliaWs = computed(() => currentWsId.value === WorkspaceId.NOVELIA)

const isNoveliaWsAdmin = computed(
  () => isNoveliaWs.value && currentWsACL.value === Acl.ADMIN
)

const { updateTheme } = useTheme()

watch(currentWorkspace, (val) => {
  document
    .querySelector("link[rel='icon']")
    ?.setAttribute(
      'href',
      val && isNoveliaWs.value ? '/favicon-novelia.svg' : '/favicon.png'
    )
})

const set = {
  currentWorkspace: async (wsId?: number) => {
    const { user } = useUser()
    let ws: Workspace | null
    if (wsId) {
      ws = user.value?.workspaces.find(({ id }) => id === wsId) as Workspace
    } else if (isWorkspaceContext.value && currentWsId.value) {
      ws = user.value?.workspaces.find(
        ({ id }) => id === currentWsId.value
      ) as Workspace
    } else {
      const { currentPartner } = usePartner()
      ws = user.value?.workspaces.find(
        ({ id }) => id === currentPartner.value?.workspace_id
      ) as Workspace

      if (!ws) currentWsId.value = currentPartner.value?.workspace_id || null
    }

    if (ws) {
      currentWorkspace.value = ws
      await refreshWorkspace()
    }
  },

  isWorkspaceContext: (val: boolean) => {
    if (val === isWorkspaceContext.value) return

    isWorkspaceContext.value = val
  },

  mainColorFromPartner: (color: string) => {
    if (color === currentWsMainColor.value) return

    currentWsMainColor.value = color
    updateTheme(currentWsMainColor.value)
  },

  invitedWsId: (val: number | null) => (invitedWsId.value = val),
  currentWorkspaceId: (id: number | null) => (currentWsId.value = id),
}

const updateWorkspaceMainColor = async (color: string) => {
  if (color === currentWsMainColor.value || !currentWsId.value) return

  await updateMainColor(currentWsId.value, color)

  const { refreshUser } = useUser()
  await refreshUser()
}

const updateWorkspaceLogo = async (file: File) => {
  if (!currentWsId.value) return

  const { refreshUser } = useUser()
  await uploadLogo(currentWsId.value, file)
  await refreshUser()
}

const resetWorkspace = () => {
  const { currentPartner } = usePartner()
  currentWorkspace.value = null
  currentWsId.value = currentPartner.value?.workspace_id || null
  currentWsACL.value = null
  currentWsDefaultPartnerType.value = null
  currentWsMainColor.value = currentPartner.value?.main_color || '#2D50E6'
  updateTheme(currentWsMainColor.value)
}

const refreshWorkspace = async () => {
  currentWsId.value = currentWorkspace.value?.id || null
  currentWsACL.value = currentWorkspace.value?.acl || null
  currentWsDefaultPartnerType.value =
    currentWorkspace.value?.default_partner_type || null
  currentWsMainColor.value = currentWorkspace.value?.main_color || '#2D50E6'
  updateTheme(currentWsMainColor.value)
}

export const useWorkspace = () => ({
  isWorkspaceContext: readonly(isWorkspaceContext),
  invitedWsId: readonly(invitedWsId),
  currentWorkspace: readonly(currentWorkspace),
  currentWsId: readonly(currentWsId),
  currentWsMainColor: readonly(currentWsMainColor),
  currentWsACL: readonly(currentWsACL),
  currentWsDefaultPartnerType: readonly(currentWsDefaultPartnerType),
  isNoveliaWs,
  isLukoWs,
  set,
  resetWorkspace,
  isNoveliaWsAdmin,
  updateWorkspaceLogo,
  updateWorkspaceMainColor,
})
